"use client";
import { useEffect, useState, useRef } from "react";
import { usePathname } from "next/navigation";
import styles from "./ChatBot.module.scss";
import If from "../../molecules/If/If";

declare global {
	interface Window {
		bmHide?: () => void;
		bmShow?: () => void;
		bmInfo?: () => void;
	}
}

export default function Chatbot() {
	const [isChatbotVisible, setIsChatbotVisible] = useState(true);
	const pathname = usePathname();
	const prevPathname = useRef<string | null>(null);
	const route = "/internacional/estados-unidos/";

	useEffect(() => {
		const addClassAndButtonToChatbotDiv = () => {
			const chatbotDiv = document.querySelector(
				'div[style*="position: fixed"][style*="bottom: 0px"][style*="right: 0%"]'
			);

			if (chatbotDiv) {
				chatbotDiv.classList.add("chatbot-div");

				const button = document.createElement("button");
				button.innerText = "X";
				button.style.position = "absolute";
				button.style.top = "5px";
				button.style.right = "5px";
				button.style.zIndex = "2147483648";
				button.style.backgroundColor = "red";
				button.style.color = "white";
				button.style.border = "none";
				button.style.borderRadius = "50%";
				button.style.width = "20px";
				button.style.height = "20px";
				button.style.display = "flex";
				button.style.alignItems = "center";
				button.style.justifyContent = "center";
				button.style.cursor = "pointer";

				button.onclick = () => {
					if (window.bmHide) window.bmHide();
					setIsChatbotVisible(false);
				};

				chatbotDiv.appendChild(button);

				return true;
			}
			return false;
		};

		const loadChatbotScript = (scriptHash: string) => {
			const existingScript = document.getElementById("chatbot-script");
			existingScript?.remove();

			const chatbotDiv = document.querySelector(
				'div[style*="position: fixed"][style*="bottom: 0px"][style*="right: 0%"]'
			);
			chatbotDiv?.remove();

			localStorage.removeItem("@botmaker-chat:maximized");
			const script = document.createElement("script");
			script.type = "text/javascript";
			script.async = true;
			script.id = "chatbot-script";
			script.src = `https://go.botmaker.com/rest/webchat/p/${scriptHash}/init.js`;

			script.onload = () => {
				if (!addClassAndButtonToChatbotDiv()) {
					const intervalId = setInterval(() => {
						if (addClassAndButtonToChatbotDiv()) {
							clearInterval(intervalId);
						}
					}, 2500);
				}
			};
			document.body.appendChild(script);
		};

		const handleRouteChange = () => {
			const isOnElectionRoute = pathname.includes(route);

			if (prevPathname.current === null) {
				if (isOnElectionRoute) {
					loadChatbotScript("C1J0CER3AL");
				} else {
					loadChatbotScript("UGN3SFLFDI");
				}
			} else if (isOnElectionRoute && !prevPathname.current.includes(route)) {
				loadChatbotScript("C1J0CER3AL");
			} else if (!isOnElectionRoute && prevPathname.current.includes(route)) {
				loadChatbotScript("UGN3SFLFDI");
			}

			prevPathname.current = pathname;
		};

		handleRouteChange();
	}, [pathname]);

	return (
		<If condition={!isChatbotVisible}>
			<button
				className={styles.chatbot_lateral}
				onClick={() => {
					if (window.bmShow) window.bmShow();
					setIsChatbotVisible(true);
				}}
			>
				{"<"}
			</button>
		</If>
	);
}
